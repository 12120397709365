import { useState } from "react";
import "./PersonButton.css";

export default function PersonButton(props){

    const onClick = (e) => {
        props.setClicked(true);
    }

    return (
        <div class={`person-btn mx-2 flex h-16 w-16 items-center 
        justify-center self-center rounded-full ${props.clicked ? 'clicked' : 'bg-white'}`}
        onClick={onClick}>
            <p className={`text ${props.label === "Both" ? 'text-xl' : 'text-2xl'}`}
                >{props.label}</p>
        </div>
    );
}