import { useEffect, useState } from "react";
import PersonButton from "../PersonButton/PersonButton";
import "./Item.css";

export default function Item(props){

    const [C_clicked, C_setClicked] = useState(false);
    const [L_clicked, L_setClicked] = useState(false);
    const [BOTH_clicked, BOTH_setClicked] = useState(false);

    const [currentClicked, setCurrentClicked] = useState("");

    // People button listeners
    useEffect(() => {
        if (C_clicked){
            setCurrentClicked("C");
        }
    }, [C_clicked]);
    useEffect(() => {
        if (L_clicked){
            setCurrentClicked("L");
        }
    }, [L_clicked]);
    useEffect(() => {
        if (BOTH_clicked){
            setCurrentClicked("BOTH");
        }
    }, [BOTH_clicked]);

    // When current clicked changes
    useEffect(() => {

        if (currentClicked === "C"){
            L_setClicked(false);
            BOTH_setClicked(false);
        }
        else if (currentClicked === "L"){
            C_setClicked(false);
            BOTH_setClicked(false);
        }
        else if (currentClicked === "BOTH"){
            C_setClicked(false);
            L_setClicked(false);
        }

        props.setItemSelectionValues(props.item.id, currentClicked);

    }, [currentClicked]);


    const onItemDelete = (e) => {
        let item = JSON.parse(e.target.dataset.item);
        if (item == null) { return; }
        if (window.confirm(`Delete item "${item.itemName}"?`)){
            props.onItemDelete(item);
        }
    }

    return (
        <div className="item-card w-full grid grid-cols-3">
            <div className="col self-center">
                <p className="item-name text-lg">{props.item.itemName}</p>
                <p className="item-price text-xl font-bold">${props.item.itemPrice}</p>
            </div>
            <div className="col flex align-center h-full">

                <PersonButton label="C" clicked={C_clicked} setClicked={C_setClicked}/>
                <PersonButton label="L" clicked={L_clicked} setClicked={L_setClicked}/>
                <PersonButton label="Both" clicked={BOTH_clicked} setClicked={BOTH_setClicked}/>
        
            </div>
            <div className="col text-right justify-end align-center h-full w-full flex">
                <button onClick={onItemDelete} data-item={`${JSON.stringify(props.item)}`} className="item-delete-btn self-center text-center bg-red-500 text-white font-bold
                text-2xl rounded-full px-4 py-2">X</button>
            </div>
        </div>
    );
}