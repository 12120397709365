import { useEffect, useState } from 'react';
import './App.css';
import ItemList from './components/ItemList/ItemList';
import { parseKrogerReceipt } from './parsers/KrogerParser';
import { parseWalmartReceipt } from './parsers/WalmartParser';
import { Modal } from "flowbite-react";

function App() {
  const [items, setItems] = useState([]);
  const [openReceiptModal, setOpenReceiptModal] = useState(false);
  const [loading, setLoading] = useState(false);  // Manage loading state

  const onKrogerUpload = async (e) => {
    setLoading(true);  // Start loading when file is being processed
    try {
      const importedItems = await parseKrogerReceipt(e);  // Ensure the receipt is parsed properly
      setItems(importedItems);  // Update items state with the result
    } catch (error) {
      console.error("Error parsing receipt:", error);
    }
    setLoading(false);  // End loading after parsing
  };

  const onWalmartUpload = async (e) => {
    setLoading(true);  // Start loading when file is being processed
    try {
      const importedItems = await parseWalmartReceipt(e);  // Ensure the receipt is parsed properly
      setItems(importedItems);  // Update items state with the result
    } catch (error) {
      console.error("Error parsing receipt:", error);
    }
    setLoading(false);  // End loading after parsing
  };

  // UseEffect to handle modal closure after items are updated
  useEffect(() => {
    if (items.length > 0 && !loading) {  // Only close if items are updated and not loading
      setOpenReceiptModal(false);  // Close modal after updating the items
    }
  }, [items, loading]);  // Depend on both `items` and `loading`

  return (
    <div className="App">
      <header className="App-header"></header>

      <div className="justify-center w-full items-center text-center">
        <button 
          className="text-lg text-white bg-blue-500 px-3 py-2 my-5 rounded"
          onClick={() => setOpenReceiptModal(true)}
        >
          Upload Receipt
        </button>

        <h1 className="text font-bold text-3xl">Total Items: {items.length}</h1>
      </div>

      <Modal show={openReceiptModal} size="md" onClose={() => setOpenReceiptModal(false)} popup>
        <Modal.Header>
          <h1 className="text-2xl font-bold m-3">Upload Receipt</h1>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center justify-center w-full items-center align-center">
            <div className="text-left">
              <label className="block mb-2 text-md font-medium text-gray-900 dark:text-white" htmlFor="kroger_file_input">
                Krogers Receipt
              </label>
              <input 
                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" 
                id="kroger_file_input" 
                type="file" 
                onChange={(e) => onKrogerUpload(e)} 
              />

              <label className="block mb-2 mt-4 text-md font-medium text-gray-900 dark:text-white" htmlFor="walmart_file_input">
                Walmart Receipt
              </label>
              <input 
                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" 
                id="walmart_file_input" 
                type="file" 
                onChange={(e) => onWalmartUpload(e)} 
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="w-full items-center flex justify-center">
          <button onClick={() => setOpenReceiptModal(false)} 
            className="text-center text text-xl text-white bg-blue-500 px-2 py-1 rounded">Import</button>
        </Modal.Footer>
      </Modal>

      <div className="h-screen flex items-center justify-center">
        <ItemList items={items} setItems={setItems} />
      </div>
    </div>
  );
}

export default App;
