const cheerio = require("cheerio");

export async function parseWalmartReceipt(e) {

    const items = [];

    e.preventDefault()
    const reader = new FileReader()
    reader.onload = async (e) => { 
      const text = (e.target.result)
      const $ = cheerio.load(text);

      const accordion = $('div[data-testid="category-accordion-"]');
      const childElements = accordion.find('div[class="pa3 pb0 ph4-m"]');
      
      childElements.map((i, element) => {
        let itemName = $(element).find('div[data-testid="productName"]').text();
        let itemPrice = $(element).find('div[class="ml-auto nowrap"]').text();

        if (itemName.trim() !== "" && itemPrice.trim() !== ""){
            items.push({
                id: i - 1,
                itemName,
                itemPrice: parseFloat(itemPrice.split("$")[1]),
                C: 0,
                L: 0
            })
        }
      });

      // Find and add tax automatically as item
      items.push({
        id: items.length + 1,
        itemName: "Tax",
        itemPrice: parseFloat($('div[class="flex justify-between pv3"]').find('span').text().split("$")[1]),
        C: 0,
        L: 0
      })
    };
    reader.readAsText(e.target.files[0])

    return items;
}